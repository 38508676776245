@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  @apply box-border m-0 p-0;
}

::-webkit-scrollbar-track {
  border-radius: 100px;
  background: transparent;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

img {
  max-width: 100%;
  width: auto;
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  /* Safari seems to support, but seems deprecated and does the same thing as the others. */
  image-rendering: -webkit-optimize-contrast;
}

@tailwind base;
@tailwind components;

/* General Style */
html {
  @apply !select-none scroll-smooth;
}

body {
  @apply !select-none font-primary text-enthusia-primary overflow-x-hidden;
}

/* animation */
.anim {
  @apply transition-all duration-300;
}

/* Titles Typography*/
h1,
.h1 {
  @apply text-[32px] md:text-[40px] xl:text-[60px] leading-[40px] md:leading-[50px] xl:leading-[76px] capitalize font-bold text-enthusia-primary;
}

h2,
.h2 {
  @apply text-28 md:text-[34px] xl:text-[50px] font-bold;
}

h3,
.h3 {
  @apply text-xl md:text-28 xl:text-[32px];
}
h4,
.h4 {
  @apply text-lg md:text-22 xl:text-28 leading-[26px] md:leading-[33px] xl:leading-[42px] font-medium;
}

p {
  @apply text-sm md:text-base xl:text-lg  leading-[21px] md:leading-[23px] xl:leading-7;
}

/* Wrapper */
.wrapper {
  @apply max-w-screen-2xl mx-auto px-4 md:px-5 xl:px-8 2xl:px-[68px];
}
.filter-box::before {
  @apply content-[""] absolute -top-[10px] left-1/2 -translate-x-1/2 block w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] border-b-[#f0f0f0];
}
.menu-sub-link2 {
  @apply block border-b border-[#D2D2D2] text-[#565656] hover:border-enthusia-primary hover:text-enthusia-primary text-base font-medium capitalize mx-[10px] p-2.5 anim;
}
.technology-tab {
  @apply relative text-lg md:text-2xl xl:text-[32px] leading-[28px] md:leading-[34px] 2xl:leading-9 font-semibold text-[#737373] pt-5 md:pt-[40px] pb-5 md:pb-[37px] md:px-0 capitalize cursor-pointer;
}
.technology-tab.active {
  @apply text-enthusia-primary after:content-[''] after:absolute after:bottom-0 after:left-0 after:rounded-t after:w-full after:h-[3px] after:bg-enthusia-primary;
}
.technology-sideTab {
  @apply text-[#737373] text-sm sm:text-lg md:text-22 lg:text-28 text-center font-semibold border-b-[2px] md:border-b-0 md:rounded-2xl md:border border-transparent md:px-8 xl:px-[65px] py-[17px] sm:whitespace-nowrap cursor-pointer anim;
}
.technology-sideTab.active {
  @apply text-enthusia-primary  md:rounded-2xl border-enthusia-primary md:border md:border-enthusia-primary anim;
}
.custom-services-line {
  @apply before:content-[""] before:absolute before:left-0 before:rounded-full before:w-[5px] before:h-full before:bg-enthusia-primary;
}

.process-dot-small-heading {
  @apply flex items-center capitalize before:content-[''] before:w-1.5 before:h-1.5 before:mr-4 before:bg-enthusia-primary before:block before:rounded-full whitespace-nowrap text-base md:text-lg font-semibold text-enthusia-primary;
}
.process-content-heading {
  @apply text-sm md:text-base lg:text-lg font-normal text-enthusia-primary pl-5 text-justify;
}

/* .custom-service-tab */
.custom-services-tab-number {
  @apply w-[70px] xl:w-[150px] opacity-0 text-[rgba(19,64,70,0.2)] font-semibold xl:pl-[50px] xl:pr-[60px] pl-5 anim;
}
.custom-services-tab-title {
  @apply text-[#737373] font-medium anim;
}

.custom-service-tab.active > .custom-services-tab-number {
  @apply text-[#B0CBCE] opacity-100 custom-services-line anim;
}
.custom-service-tab.active > .custom-services-tab-title {
  @apply text-enthusia-primary anim;
}

.dot::after {
  @apply content-[""] block w-3 h-3 bg-enthusia-primary rounded-full absolute right-0 top-1/2 -translate-y-1/2;
}
.bottom-line::after {
  @apply inline-block content-[''] h-[5px] w-full rounded-t-[4px] bg-enthusia-primary absolute bottom-0 left-0;
}
.left-line::after {
  @apply content-[""] absolute top-12 lg:top-[70px] left-1/2 -translate-x-1/2 block h-full border-l-2 border-dashed -z-10;
}

.left-small-header-line::before {
  @apply content-[""] inline-block w-[6px] h-[38px] bg-[#737373] group-hover:bg-enthusia-primary rounded-tr-md rounded-br-md -ml-5 anim;
}

.services-offer-border-left::before {
  @apply content-[""] absolute top-0 left-0 w-[5px] h-full block bg-[#ede9e8] rounded-[10px];
}

.side-line::after,
.side-line::before {
  @apply content-[""] hidden md:block w-[2px] h-full bg-gradient-to-t absolute right-0 top-0 from-[rgba(19,64,70,0)] via-[rgba(19,64,70,1)] to-[rgba(19,64,70,0)];
}

.side-line::before {
  @apply left-0;
}
/* swiper Css */
.swiper {
  @apply !overflow-y-visible;
}
.swiper-pagination {
  @apply -mb-10;
}
.swiper-pagination-bullet {
  @apply !w-2 !h-2 !rounded-full !bg-[#154045] !bg-opacity-50 -mx-3;
}
.swiper-pagination-bullet-active {
  @apply !w-4 !opacity-100;
}
.swiper-button-next {
  @apply !right-2 !top-[33px] !left-auto bg-white;
}
.swiper-button-prev {
  @apply !right-10 !top-[33px] !left-auto bg-white;
}

.filter-bags {
  @apply text-base font-medium text-enthusia-primary bg-[#F0F0F0] rounded-full cursor-pointer px-5 py-2;
}

.filter-dropdown {
  @apply hidden w-[230px] h-auto filter-box absolute left-1/2 -translate-x-1/2 top-[55px] bg-[#fff] border border-[#F0F0F0] rounded-[12px] drop-shadow-xl p-3 z-10 anim;
}

.filter-bags:hover,
.filter-bags.active {
  @apply text-[#F0F0F0] bg-enthusia-primary anim;
}

.filter-button:hover > .filter-dropdown {
  @apply block;
}

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  @apply min-h-[200px] !border-[2px];
}
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  @apply !border-[#b5b7be] !drop-shadow-none;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  @apply !border-[2px] !border-[#b5b7be] !border-b-[0px];
}

@tailwind utilities;

/* Custom Style */

@property --gradient-angle {
  syntax: "<angle>";
  initial-value: -67deg;
  inherits: false;
}
@property --gradient-angle-left {
  syntax: "<angle>";
  initial-value: 65deg;
  inherits: false;
}

.dropdown_one {
  display: none;
  transition: 0.5s all;
  z-index: 1;
}

.dropdown_two {
  display: none;
  transition: 0.5s all;
  z-index: 2;
}

.dropdown_one_list .rigtht_arrow {
  display: none;
}
.dropdown_one_list:hover .rigtht_arrow {
  display: block;
}

.dropList_list:hover .dropdown_one,
.dropdown_one_list:hover .dropdown_two {
  display: block;
}

.header-linier {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: linear-gradient(rgba(19, 64, 70, 1), rgba(19, 64, 70, 1))
      padding-box,
    linear-gradient(90deg, rgba(19, 64, 70, 1) 15%, rgba(231, 232, 235, 1))
      border-box;
  border: 1px solid transparent;
}
.header-linier-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: linear-gradient(rgba(242, 242, 242, 1), rgba(231, 236, 237, 1))
      padding-box,
    linear-gradient(265deg, rgba(19, 64, 70, 1) 15%, rgba(231, 232, 235, 1))
      border-box;
  border: 1px solid transparent;
}

.box-inner {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgb(242, 243, 245)
  );
  inset: 3px;
}

.right-box {
  background: conic-gradient(
    from var(--gradient-angle),
    var(--clt-1),
    var(--clt-2)
  );
  -webkit-animation: none;
  animation: none;
}

.left-box {
  background: conic-gradient(
    from var(--gradient-angle-left),
    var(--clt-2),
    var(--clt-1)
  );
  -webkit-animation: none;
  animation: none;
}

.left-box.active {
  background: conic-gradient(
    from var(--gradient-angle-left),
    var(--clt-2),
    var(--clt-1)
  );
  animation: rotation 10s linear forwards;
  -webkit-animation: rotation 10s linear forwards;
}

.gradient-border-rightbox {
  background: linear-gradient(to left, rgb(241, 245, 247), rgb(255, 255, 255))
      padding-box,
    linear-gradient(215deg, rgba(19, 64, 70, 1), rgba(231, 232, 235, 1))
      border-box;
  border: 2px solid transparent;
}

.gradient-border-leftbox {
  background: linear-gradient(
        to left,
        rgba(255, 255, 255, 1),
        rgba(231, 232, 235, 1)
      )
      padding-box,
    linear-gradient(135deg, rgba(19, 64, 70, 1), rgba(231, 232, 235, 1))
      border-box;
  border: 2px solid transparent;
}

.right-box.active {
  background: conic-gradient(
    from var(--gradient-angle),
    var(--clt-1),
    var(--clt-2)
  );
  animation: rotation 10s linear forwards;
  -webkit-animation: rotation 10s linear forwards;
}

.gradient-border-2 {
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(231, 232, 235, 1))
      padding-box,
    linear-gradient(360deg, rgba(19, 64, 70, 1), rgba(231, 232, 235, 1) 60%)
      border-box;
  border: 3px solid transparent;
  border-radius: 100%;
}

.gradient-bg {
  background: linear-gradient(
    90deg,
    rgba(19, 64, 70, 0.1),
    rgba(120, 144, 147, 0.1),
    rgba(187, 214, 218, 0.1),
    rgba(199, 225, 227, 0.1),
    rgba(222, 234, 238, 0.27)
  );
}

.hiring-gradient {
  background: linear-gradient(
    45deg,
    rgba(19, 64, 70, 0),
    rgba(19, 64, 70, 0.2)
  );
}

.hiring-text-gradient {
  background: linear-gradient(
    225deg,
    rgba(19, 64, 70, 1),
    rgba(19, 64, 70, 0.51)
  );
  outline: 3px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}
.hiring-text-gradient-span2 {
  background: linear-gradient(
    45deg,
    rgba(19, 64, 70, 0.4),
    rgba(19, 64, 70, 0.14)
  );
  outline: 2px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

.footer-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0),
    rgba(19, 64, 70, 0.2)
  );
}
.footer-icon-border {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(19, 64, 70, 1), rgba(19, 64, 70, 1))
      padding-box,
    linear-gradient(360deg, rgba(19, 64, 70, 1) 15%, rgba(231, 232, 235, 1))
      border-box;
  border: 2px solid transparent;
}

.footer-social-icon-bg {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.58)
  );
}
.bg-radial-outside-pink {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  background: radial-gradient(
    circle farthest-side,
    rgba(234, 64, 152, 1),
    rgba(234, 64, 152, 0)
  );
  opacity: 10%;
}
.bg-radial-outside-orange {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  background: radial-gradient(
    circle farthest-side,
    rgba(240, 80, 55, 1),
    rgba(240, 80, 55, 0)
  );
  opacity: 10%;
}
.bg-radial-outside-green {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  background: radial-gradient(
    circle farthest-side,
    rgba(21, 64, 69, 1),
    rgba(21, 64, 69, 0)
  );
  opacity: 10%;
}

.hrours::before {
  position: relative;
  display: contents;
  content: "";
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 50%;
}
.contact-border {
  background: linear-gradient(
        90deg,
        rgba(231, 236, 237, 1) 50%,
        rgba(242, 242, 242, 1)
      )
      padding-box,
    linear-gradient(130deg, rgba(19, 64, 70, 1), rgba(19, 64, 70, 0)) border-box;
  border: 2px solid transparent;
}

.development-border {
  background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 40%,
        rgba(231, 236, 237, 1)
      )
      padding-box,
    linear-gradient(330deg, rgba(19, 64, 70, 1), rgba(19, 64, 70, 0)) border-box;
  border: 2px solid transparent;
}

/* Logo-animation */
.logo-animation {
  padding: 2rem;
  border-radius: 1rem;
  position: relative;
  transform-style: preserve-3d;
  transform: perspective(5000px) rotateY(var(--rotateX)) rotateX(var(--rotateY));
}

.clip-path-polygon {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

/* mega-menu */
.step1 {
  opacity: 0;
  transform: translateY(100px);
  visibility: hidden;
}
.step2 {
  opacity: 0;
  transform: translateY(100px);
  visibility: hidden;
}
.step3 {
  opacity: 0;
  transform: translateY(100px);
  visibility: hidden;
}

.menu-link:hover > .step1 {
  opacity: 100;
  transform: translateY(0);
  visibility: visible;
}

.menu-link .step1 .menu-sub-link:hover > .step2 {
  opacity: 100;
  transform: translateY(0);
  visibility: visible;
}

.menu-link .step1 .menu-sub-link .step2 .menu-sub-link2:hover > .step3 {
  opacity: 100;
  transform: translateY(0);
  visibility: visible;
}
/* mobile menu */

.process-gradient-border {
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))
      padding-box,
    linear-gradient(135deg, rgba(19, 64, 70, 1), rgba(231, 232, 235, 0.4))
      border-box;
  border: 2px solid transparent;
  border-radius: 30px 10px 10px 30px;
}
.process-gradient-border-gray {
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))
      padding-box,
    linear-gradient(135deg, rgba(144, 144, 144, 1), rgba(231, 232, 235, 0.4))
      border-box;
  border: 2px solid transparent;
  border-radius: 30px 10px 10px 30px;
}

.process-inner-active {
  border-radius: 50%;
  background: linear-gradient(rgba(19, 64, 70, 1), rgba(19, 64, 70, 1))
      padding-box,
    linear-gradient(265deg, rgba(19, 64, 70, 1) 15%, rgba(231, 232, 235, 1))
      border-box;
  border: 2px solid transparent;
}
.process-inner {
  border-radius: 50%;
  background: linear-gradient(rgba(144, 148, 148, 1), rgba(144, 148, 148, 1))
      padding-box,
    linear-gradient(265deg, rgba(180, 184, 184, 1) 15%, rgba(180, 184, 184, 1))
      border-box;
  border: 2px solid transparent;
}
.customs-services {
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))
      padding-box,
    linear-gradient(135deg, rgba(19, 64, 70, 1) 20%, rgba(19, 64, 70, 0))
      border-box;
}

.customs-services-hover {
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))
      padding-box,
    linear-gradient(135deg, rgba(115, 115, 115, 1) 20%, rgba(115, 115, 115, 0))
      border-box;
}
.customs-services-active,
.customs-services-hover:hover {
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))
      padding-box,
    linear-gradient(135deg, rgba(19, 64, 70, 1) 20%, rgba(19, 64, 70, 0))
      border-box;
}

.architecture-patterns-box-small {
  background: linear-gradient(
        330deg,
        rgba(255, 255, 255, 0.8) 40%,
        rgba(231, 236, 237, 1)
      )
      padding-box,
    linear-gradient(135deg, rgba(19, 64, 70, 1) 20%, rgba(19, 64, 70, 0))
      border-box;
  border: 2px solid transparent;
}

.contact-small-box {
  background: linear-gradient(
        330deg,
        rgba(246, 246, 246, 1) 40%,
        rgba(246, 246, 246, 1)
      )
      padding-box,
    linear-gradient(135deg, rgba(19, 64, 70, 1) 20%, rgba(19, 64, 70, 0))
      border-box;
  border: 2px solid transparent;
}

.share-gradient {
  background: linear-gradient(
    225deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.58)
  );
}

/* Swipe Slider Arrow */
.mySwiper-services .swiper-button-next {
  top: 32px !important;
  background-image: url(Assets/svg/right_arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
.mySwiper-services .swiper-button-next::after,
.mySwiper-services .swiper-button-prev::after {
  display: none;
}

.mySwiper-services .swiper-button-prev {
  background-image: url(Assets/svg/right_arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: rotate(180deg);
}

/* swiper button Blog Page */
.mySwiper-blog .swiper-button-next::after {
  content: url(Assets/svg/right_arrow.svg) !important;
  margin-bottom: 15px;
}
.mySwiper-blog .swiper-button-prev::after {
  content: url(Assets/svg/right_arrow.svg) !important;
  margin-top: 15px;
  transform: rotate(180deg);
}

.mySwiper-blog .swiper-button-next,
.mySwiper-blog .swiper-button-prev {
  position: absolute;
  display: flex;
  align-items: center;
  justify-items: center;
  max-width: 40px;
  max-height: 40px;
  border: 1px solid #134046;
  border-radius: 50%;
}

.mySwiper-blog .swiper-button-next {
  top: -88px !important;
}
.mySwiper-blog .swiper-button-prev {
  top: -88px !important;
  right: 60px !important;
}

.truncate-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-open-body {
  overflow: hidden !important;
}

/* ================================ animation Start =============================== */

/* button Animation */
.button-animation {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 15%;
  height: 100%;
  transform: skew(-10deg, 0deg);
  animation: move 3s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}

@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

.animation-updawon {
  animation: updown 2s cubic-bezier(0.25, 0.75, 0.5, 1) infinite;
}

@keyframes updown {
  0%,
  100% {
    transform: translateY(0);
  }
  20%,
  80% {
    transform: translateY(-10px);
  }
  40%,
  60% {
    transform: translateY(-5px);
  }
}

/* contact From phone input style */
.PhoneInput {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PhoneInputCountry {
  max-width: 45px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8d8d8d;
  border-bottom: 1px solid #8d8d8d;
  padding: 10px 0;
}
.PhoneInput .PhoneInputCountryIconImg {
  width: 25px;
  height: 20px;
  order: 2;
}

.PhoneInputInput {
  width: calc(100% - 45px);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #8d8d8d;
  border-bottom: 1px solid #8d8d8d;
  padding: 10px 0;
  background: transparent;
  border-radius: 0px !important;
}

.PhoneInputCountry:focus-visible,
.PhoneInputInput:focus-visible {
  outline: none;
}
.PhoneInput .PhoneInputCountrySelect {
  order: 1;
  width: 15px;
  height: 15px;
  background: transparent;
}

/* Like Animation */
.like:hover > .animation-like,
.animation-like {
  animation: liked 1s cubic-bezier(0.25, 0.75, 0.5, 1) forwards;
}
.like:hover > .animation-like-heart,
.animation-like-heart {
  animation: liked-heart 0.5s cubic-bezier(0.25, 0.75, 0.5, 1) both;
  animation-delay: 500ms;
}

@keyframes liked {
  0% {
    transform: scale(0%);
    transform: rotateX("-45deg");
  }
  100% {
    transform: scale(100%);
    transform: rotateX("-180deg");
  }
}

@keyframes liked-heart {
  0% {
    transition: transform 1s;
    transform: rotate(-30deg);
    transform-origin: bottom center;
  }
  50% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Arrow Animation */
.arrow:hover > .animation-arrow {
  animation: arrowAnimation 1.5s linear infinite;
}

@keyframes arrowAnimation {
  0% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(-5px);
  }
}

/* Share Animation */
.share:hover > .animation-share {
  animation: shareAnimation 1s linear infinite;
}

@keyframes shareAnimation {
  0% {
    transform: translateY(30px) translateX(-30px);
  }
  100% {
    transform: translateY(-30px) translateX(30px);
  }
}

:root {
  --clt-1: #134046;
  --clt-2: #e7e8eb;
  --clt-3: #d6d8df;
}

/* Responsive media */
@media only screen and (max-width: 1536px) {
  .mySwiper-blog .swiper-button-next {
    top: -74px !important;
  }
  .mySwiper-blog .swiper-button-prev {
    top: -74px !important;
    right: 60px !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1720px) {
  .wrapper {
    @apply ml-[157px] px-[24px];
  }
}
@media only screen and (max-width: 1024px) {
  .contact-border {
    background: linear-gradient(
          60deg,
          rgba(231, 236, 237, 1) 50%,
          rgba(242, 242, 242, 1)
        )
        padding-box,
      linear-gradient(135deg, rgba(19, 64, 70, 1), rgba(19, 64, 70, 0))
        border-box;
    border: 2px solid transparent;
  }
  @property --gradient-angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
  @property --gradient-angle-left {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
  .left-box {
    background: conic-gradient(
      from var(--gradient-angle-left),
      var(--clt-1),
      var(--clt-2)
    );
    animation: none;
  }
}

@media only screen and (max-width: 768px) {
  .gradient-border-rightbox {
    border: 1px solid transparent;
  }
  .gradient-border-leftbox {
    border: 1px solid transparent;
  }
  .process-gradient-border {
    border: 1px solid transparent;
    border-radius: 20px 10px 10px 20px;
  }
  .process-gradient-border-gray {
    border: 1px solid transparent;
    border-radius: 20px 10px 10px 20px;
  }
  .architecture-patterns-box-small {
    border: 1px solid transparent;
  }
  .contact-border {
    background: linear-gradient(
          60deg,
          rgba(231, 236, 237, 1) 50%,
          rgba(242, 242, 242, 1)
        )
        padding-box,
      linear-gradient(135deg, rgba(19, 64, 70, 1), rgba(19, 64, 70, 0))
        border-box;
    border: 1px solid transparent;
  }
  .Profile {
    padding: 2px !important;
  }
  .mySwiper-blog .swiper-button-next,
  .mySwiper-blog .swiper-button-prev {
    width: 30px;
    height: 30px;
  }
  .mySwiper-blog .swiper-button-next {
    top: -40px !important;
  }
  .mySwiper-blog .swiper-button-prev {
    top: -40px !important;
    right: 50px !important;
  }
}

.parallax-container {
  perspective: 1000px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parallax-image {
  width: 180px;
  height: 250px;
  max-width: 264px;
  max-height: 375px;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.Profile {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 100%;
  padding: 7px;
}

.Profile-border {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  padding: 0px;
  -webkit-animation: rotate 5.5s infinite linear;
  animation: rotate 5.5s infinite linear;
  animation: none;
}

.Profile-border-segment {
  position: absolute;
  top: -4px;
  left: -4px;
}

.Profile-border-segmentContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform-origin: 50% 50%;
}

.Profile-border.active {
  -webkit-animation: rotate 5.5s infinite linear;
  animation: rotate 5.5s infinite linear;
}

.Profile-border-segmentContainer:nth-child(1) {
  transform: rotate(14deg);
}
.Profile-border-segmentContainer:nth-child(1) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.013333333333333s;
  animation-delay: 0.013333333333333s;
}
.Profile-border-segmentContainer:nth-child(2) {
  transform: rotate(26deg);
}
.Profile-border-segmentContainer:nth-child(2) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.026666666666667s;
  animation-delay: 0.026666666666667s;
}
.Profile-border-segmentContainer:nth-child(3) {
  transform: rotate(38deg);
}
.Profile-border-segmentContainer:nth-child(3) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.04s;
  animation-delay: 0.04s;
}
.Profile-border-segmentContainer:nth-child(4) {
  transform: rotate(50deg);
}
.Profile-border-segmentContainer:nth-child(4) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.053333333333333s;
  animation-delay: 0.053333333333333s;
}
.Profile-border-segmentContainer:nth-child(5) {
  transform: rotate(62deg);
}
.Profile-border-segmentContainer:nth-child(5) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.066666666666667s;
  animation-delay: 0.066666666666667s;
}
.Profile-border-segmentContainer:nth-child(6) {
  transform: rotate(74deg);
}
.Profile-border-segmentContainer:nth-child(6) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.08s;
  animation-delay: 0.08s;
}
.Profile-border-segmentContainer:nth-child(7) {
  transform: rotate(86deg);
}
.Profile-border-segmentContainer:nth-child(7) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.093333333333333s;
  animation-delay: 0.093333333333333s;
}
.Profile-border-segmentContainer:nth-child(8) {
  transform: rotate(98deg);
}
.Profile-border-segmentContainer:nth-child(8) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.106666666666667s;
  animation-delay: 0.106666666666667s;
}
.Profile-border-segmentContainer:nth-child(9) {
  transform: rotate(110deg);
}
.Profile-border-segmentContainer:nth-child(9) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.12s;
  animation-delay: 0.12s;
}
.Profile-border-segmentContainer:nth-child(10) {
  transform: rotate(122deg);
}
.Profile-border-segmentContainer:nth-child(10) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.133333333333333s;
  animation-delay: 0.133333333333333s;
}
.Profile-border-segmentContainer:nth-child(11) {
  transform: rotate(134deg);
}
.Profile-border-segmentContainer:nth-child(11) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.146666666666667s;
  animation-delay: 0.146666666666667s;
}
.Profile-border-segmentContainer:nth-child(12) {
  transform: rotate(146deg);
}
.Profile-border-segmentContainer:nth-child(12) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}
.Profile-border-segmentContainer:nth-child(13) {
  transform: rotate(158deg);
}
.Profile-border-segmentContainer:nth-child(13) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.173333333333333s;
  animation-delay: 0.173333333333333s;
}
.Profile-border-segmentContainer:nth-child(14) {
  transform: rotate(170deg);
}
.Profile-border-segmentContainer:nth-child(14) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.186666666666667s;
  animation-delay: 0.186666666666667s;
}
.Profile-border-segmentContainer:nth-child(15) {
  transform: rotate(182deg);
}
.Profile-border-segmentContainer:nth-child(15) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.Profile-border-segmentContainer:nth-child(16) {
  transform: rotate(194deg);
}
.Profile-border-segmentContainer:nth-child(16) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.213333333333333s;
  animation-delay: 0.213333333333333s;
}
.Profile-border-segmentContainer:nth-child(17) {
  transform: rotate(206deg);
}
.Profile-border-segmentContainer:nth-child(17) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.226666666666667s;
  animation-delay: 0.226666666666667s;
}
.Profile-border-segmentContainer:nth-child(18) {
  transform: rotate(218deg);
}
.Profile-border-segmentContainer:nth-child(18) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.24s;
  animation-delay: 0.24s;
}
.Profile-border-segmentContainer:nth-child(19) {
  transform: rotate(230deg);
}
.Profile-border-segmentContainer:nth-child(19) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.253333333333333s;
  animation-delay: 0.253333333333333s;
}
.Profile-border-segmentContainer:nth-child(20) {
  transform: rotate(242deg);
}
.Profile-border-segmentContainer:nth-child(20) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.266666666666667s;
  animation-delay: 0.266666666666667s;
}
.Profile-border-segmentContainer:nth-child(21) {
  transform: rotate(254deg);
}
.Profile-border-segmentContainer:nth-child(21) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.28s;
  animation-delay: 0.28s;
}
.Profile-border-segmentContainer:nth-child(22) {
  transform: rotate(266deg);
}
.Profile-border-segmentContainer:nth-child(22) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.293333333333333s;
  animation-delay: 0.293333333333333s;
}
.Profile-border-segmentContainer:nth-child(23) {
  transform: rotate(278deg);
}
.Profile-border-segmentContainer:nth-child(23) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.306666666666667s;
  animation-delay: 0.306666666666667s;
}
.Profile-border-segmentContainer:nth-child(24) {
  transform: rotate(290deg);
}
.Profile-border-segmentContainer:nth-child(24) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
}
.Profile-border-segmentContainer:nth-child(25) {
  transform: rotate(302deg);
}
.Profile-border-segmentContainer:nth-child(25) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.333333333333333s;
  animation-delay: 0.333333333333333s;
}
.Profile-border-segmentContainer:nth-child(26) {
  transform: rotate(314deg);
}
.Profile-border-segmentContainer:nth-child(26) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.346666666666667s;
  animation-delay: 0.346666666666667s;
}
.Profile-border-segmentContainer:nth-child(27) {
  transform: rotate(326deg);
}
.Profile-border-segmentContainer:nth-child(27) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.36s;
  animation-delay: 0.36s;
}
.Profile-border-segmentContainer:nth-child(28) {
  transform: rotate(338deg);
}
.Profile-border-segmentContainer:nth-child(28) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.373333333333333s;
  animation-delay: 0.373333333333333s;
}
.Profile-border-segmentContainer:nth-child(29) {
  transform: rotate(350deg);
}
.Profile-border-segmentContainer:nth-child(29) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.386666666666667s;
  animation-delay: 0.386666666666667s;
}
.Profile-border-segmentContainer:nth-child(30) {
  transform: rotate(362deg);
}
.Profile-border-segmentContainer:nth-child(30) .Profile-border-segment {
  transform-origin: 180px 50%;
  -webkit-animation: scale 2.5s infinite linear;
  animation: scale 2.5s infinite linear;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes scale {
  0% {
    transform: scale(1, 1);
  }
  18% {
    transform: scale(0.01, 0.01);
  }
  33% {
    transform: scale(0.01, 0.01);
  }
  51% {
    transform: scale(1, 1);
  }
}
@keyframes scale {
  0% {
    transform: scale(1, 1);
  }
  18% {
    transform: scale(0.01, 0.01);
  }
  33% {
    transform: scale(0.01, 0.01);
  }
  51% {
    transform: scale(1, 1);
  }
}

/* extra Animation */

/* Home Page  */
.arrow-animation-smallshap {
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: draw 3s ease-out forwards;
  animation-delay: 0.5s;
  animation-play-state: paused;
}

.arrow-animation {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 3s ease-out forwards;
  animation-play-state: paused;
}

.arrow-animation-smallshap.active,
.arrow-animation.active {
  animation-play-state: running;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.rotating360 {
  animation-play-state: paused;
  animation: rotate360 3s linear forwards;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}
.rotating360.active {
  animation-play-state: running;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.process-arrow-animation {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 2s ease-out forwards;
}

.process-arrow-laptopMedium-animation {
  stroke-dasharray: 1300;
  stroke-dashoffset: 1300;
  animation: laptopMedium 2s ease-out forwards;
}

@keyframes laptopMedium {
  to {
    stroke-dashoffset: 3000;
  }
}

.process-arrow-long-animation {
  stroke-dasharray: 3000;
  stroke-dashoffset: 3000;
  animation: draw-long 2s ease-out forwards;
}

@keyframes draw-long {
  to {
    stroke-dashoffset: 6000;
  }
}
.process-arrow-medium-animation {
  stroke-dasharray: 2500;
  stroke-dashoffset: 2500;
  animation: draw-medium 2s ease-out forwards;
}

@keyframes draw-medium {
  to {
    stroke-dashoffset: 5000;
  }
}

.process-arrow-shortRight-animation {
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
  animation: draw-shortRight 2s ease-out forwards;
}

@keyframes draw-shortRight {
  to {
    stroke-dashoffset: 3000;
  }
}

.process-arrow-short-animation {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw-short 2s ease-out forwards;
}

@keyframes draw-short {
  to {
    stroke-dashoffset: 2300;
  }
}
.process-arrow-short2-animation {
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
  animation: draw-short2 2s ease-out forwards;
}

@keyframes draw-short2 {
  to {
    stroke-dashoffset: 2700;
  }
}

.process-arrow-mediumLeft-animation {
  stroke-dasharray: 5000;
  stroke-dashoffset: 5000;
  animation: draw-mediumLeft 2s ease-out forwards;
}

@keyframes draw-mediumLeft {
  to {
    stroke-dashoffset: 3000;
  }
}

@media only screen and (max-width: 1024px) {
  .Profile-border-segmentContainer:nth-child(1) {
    transform: rotate(12deg);
  }
  .Profile-border-segmentContainer:nth-child(1) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.013333333333333s;
    animation-delay: 0.013333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(2) {
    transform: rotate(24deg);
  }
  .Profile-border-segmentContainer:nth-child(2) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.026666666666667s;
    animation-delay: 0.026666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(3) {
    transform: rotate(36deg);
  }
  .Profile-border-segmentContainer:nth-child(3) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
  }
  .Profile-border-segmentContainer:nth-child(4) {
    transform: rotate(48deg);
  }
  .Profile-border-segmentContainer:nth-child(4) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.053333333333333s;
    animation-delay: 0.053333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(5) {
    transform: rotate(60deg);
  }
  .Profile-border-segmentContainer:nth-child(5) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.066666666666667s;
    animation-delay: 0.066666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(6) {
    transform: rotate(72deg);
  }
  .Profile-border-segmentContainer:nth-child(6) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.08s;
    animation-delay: 0.08s;
  }
  .Profile-border-segmentContainer:nth-child(7) {
    transform: rotate(84deg);
  }
  .Profile-border-segmentContainer:nth-child(7) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.093333333333333s;
    animation-delay: 0.093333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(8) {
    transform: rotate(96deg);
  }
  .Profile-border-segmentContainer:nth-child(8) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.106666666666667s;
    animation-delay: 0.106666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(9) {
    transform: rotate(108deg);
  }
  .Profile-border-segmentContainer:nth-child(9) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.12s;
    animation-delay: 0.12s;
  }
  .Profile-border-segmentContainer:nth-child(10) {
    transform: rotate(120deg);
  }
  .Profile-border-segmentContainer:nth-child(10) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.133333333333333s;
    animation-delay: 0.133333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(11) {
    transform: rotate(132deg);
  }
  .Profile-border-segmentContainer:nth-child(11) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.146666666666667s;
    animation-delay: 0.146666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(12) {
    transform: rotate(144deg);
  }
  .Profile-border-segmentContainer:nth-child(12) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.16s;
    animation-delay: 0.16s;
  }
  .Profile-border-segmentContainer:nth-child(13) {
    transform: rotate(156deg);
  }
  .Profile-border-segmentContainer:nth-child(13) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.173333333333333s;
    animation-delay: 0.173333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(14) {
    transform: rotate(168deg);
  }
  .Profile-border-segmentContainer:nth-child(14) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.186666666666667s;
    animation-delay: 0.186666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(15) {
    transform: rotate(180deg);
  }
  .Profile-border-segmentContainer:nth-child(15) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .Profile-border-segmentContainer:nth-child(16) {
    transform: rotate(192deg);
  }
  .Profile-border-segmentContainer:nth-child(16) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.213333333333333s;
    animation-delay: 0.213333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(17) {
    transform: rotate(204deg);
  }
  .Profile-border-segmentContainer:nth-child(17) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.226666666666667s;
    animation-delay: 0.226666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(18) {
    transform: rotate(216deg);
  }
  .Profile-border-segmentContainer:nth-child(18) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.24s;
    animation-delay: 0.24s;
  }
  .Profile-border-segmentContainer:nth-child(19) {
    transform: rotate(228deg);
  }
  .Profile-border-segmentContainer:nth-child(19) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.253333333333333s;
    animation-delay: 0.253333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(20) {
    transform: rotate(240deg);
  }
  .Profile-border-segmentContainer:nth-child(20) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.266666666666667s;
    animation-delay: 0.266666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(21) {
    transform: rotate(252deg);
  }
  .Profile-border-segmentContainer:nth-child(21) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.28s;
    animation-delay: 0.28s;
  }
  .Profile-border-segmentContainer:nth-child(22) {
    transform: rotate(264deg);
  }
  .Profile-border-segmentContainer:nth-child(22) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.293333333333333s;
    animation-delay: 0.293333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(23) {
    transform: rotate(276deg);
  }
  .Profile-border-segmentContainer:nth-child(23) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.306666666666667s;
    animation-delay: 0.306666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(24) {
    transform: rotate(288deg);
  }
  .Profile-border-segmentContainer:nth-child(24) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.32s;
    animation-delay: 0.32s;
  }
  .Profile-border-segmentContainer:nth-child(25) {
    transform: rotate(300deg);
  }
  .Profile-border-segmentContainer:nth-child(25) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.333333333333333s;
    animation-delay: 0.333333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(26) {
    transform: rotate(312deg);
  }
  .Profile-border-segmentContainer:nth-child(26) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.346666666666667s;
    animation-delay: 0.346666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(27) {
    transform: rotate(324deg);
  }
  .Profile-border-segmentContainer:nth-child(27) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.36s;
    animation-delay: 0.36s;
  }
  .Profile-border-segmentContainer:nth-child(28) {
    transform: rotate(336deg);
  }
  .Profile-border-segmentContainer:nth-child(28) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.373333333333333s;
    animation-delay: 0.373333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(29) {
    transform: rotate(348deg);
  }
  .Profile-border-segmentContainer:nth-child(29) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.386666666666667s;
    animation-delay: 0.386666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(30) {
    transform: rotate(360deg);
  }
  .Profile-border-segmentContainer:nth-child(30) .Profile-border-segment {
    transform-origin: 120px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
}

@media only screen and (max-width: 768px) {
  .Profile-border-segmentContainer:nth-child(1) {
    transform: rotate(12deg);
  }
  .Profile-border-segmentContainer:nth-child(1) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.013333333333333s;
    animation-delay: 0.013333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(2) {
    transform: rotate(24deg);
  }
  .Profile-border-segmentContainer:nth-child(2) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.026666666666667s;
    animation-delay: 0.026666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(3) {
    transform: rotate(36deg);
  }
  .Profile-border-segmentContainer:nth-child(3) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
  }
  .Profile-border-segmentContainer:nth-child(4) {
    transform: rotate(48deg);
  }
  .Profile-border-segmentContainer:nth-child(4) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.053333333333333s;
    animation-delay: 0.053333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(5) {
    transform: rotate(60deg);
  }
  .Profile-border-segmentContainer:nth-child(5) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.066666666666667s;
    animation-delay: 0.066666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(6) {
    transform: rotate(72deg);
  }
  .Profile-border-segmentContainer:nth-child(6) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.08s;
    animation-delay: 0.08s;
  }
  .Profile-border-segmentContainer:nth-child(7) {
    transform: rotate(84deg);
  }
  .Profile-border-segmentContainer:nth-child(7) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.093333333333333s;
    animation-delay: 0.093333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(8) {
    transform: rotate(96deg);
  }
  .Profile-border-segmentContainer:nth-child(8) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.106666666666667s;
    animation-delay: 0.106666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(9) {
    transform: rotate(108deg);
  }
  .Profile-border-segmentContainer:nth-child(9) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.12s;
    animation-delay: 0.12s;
  }
  .Profile-border-segmentContainer:nth-child(10) {
    transform: rotate(120deg);
  }
  .Profile-border-segmentContainer:nth-child(10) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.133333333333333s;
    animation-delay: 0.133333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(11) {
    transform: rotate(132deg);
  }
  .Profile-border-segmentContainer:nth-child(11) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.146666666666667s;
    animation-delay: 0.146666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(12) {
    transform: rotate(144deg);
  }
  .Profile-border-segmentContainer:nth-child(12) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.16s;
    animation-delay: 0.16s;
  }
  .Profile-border-segmentContainer:nth-child(13) {
    transform: rotate(156deg);
  }
  .Profile-border-segmentContainer:nth-child(13) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.173333333333333s;
    animation-delay: 0.173333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(14) {
    transform: rotate(168deg);
  }
  .Profile-border-segmentContainer:nth-child(14) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.186666666666667s;
    animation-delay: 0.186666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(15) {
    transform: rotate(180deg);
  }
  .Profile-border-segmentContainer:nth-child(15) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .Profile-border-segmentContainer:nth-child(16) {
    transform: rotate(192deg);
  }
  .Profile-border-segmentContainer:nth-child(16) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.213333333333333s;
    animation-delay: 0.213333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(17) {
    transform: rotate(204deg);
  }
  .Profile-border-segmentContainer:nth-child(17) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.226666666666667s;
    animation-delay: 0.226666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(18) {
    transform: rotate(216deg);
  }
  .Profile-border-segmentContainer:nth-child(18) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.24s;
    animation-delay: 0.24s;
  }
  .Profile-border-segmentContainer:nth-child(19) {
    transform: rotate(228deg);
  }
  .Profile-border-segmentContainer:nth-child(19) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.253333333333333s;
    animation-delay: 0.253333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(20) {
    transform: rotate(240deg);
  }
  .Profile-border-segmentContainer:nth-child(20) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.266666666666667s;
    animation-delay: 0.266666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(21) {
    transform: rotate(252deg);
  }
  .Profile-border-segmentContainer:nth-child(21) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.28s;
    animation-delay: 0.28s;
  }
  .Profile-border-segmentContainer:nth-child(22) {
    transform: rotate(264deg);
  }
  .Profile-border-segmentContainer:nth-child(22) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.293333333333333s;
    animation-delay: 0.293333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(23) {
    transform: rotate(276deg);
  }
  .Profile-border-segmentContainer:nth-child(23) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.306666666666667s;
    animation-delay: 0.306666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(24) {
    transform: rotate(288deg);
  }
  .Profile-border-segmentContainer:nth-child(24) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.32s;
    animation-delay: 0.32s;
  }
  .Profile-border-segmentContainer:nth-child(25) {
    transform: rotate(300deg);
  }
  .Profile-border-segmentContainer:nth-child(25) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.333333333333333s;
    animation-delay: 0.333333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(26) {
    transform: rotate(312deg);
  }
  .Profile-border-segmentContainer:nth-child(26) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.346666666666667s;
    animation-delay: 0.346666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(27) {
    transform: rotate(324deg);
  }
  .Profile-border-segmentContainer:nth-child(27) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.36s;
    animation-delay: 0.36s;
  }
  .Profile-border-segmentContainer:nth-child(28) {
    transform: rotate(336deg);
  }
  .Profile-border-segmentContainer:nth-child(28) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.373333333333333s;
    animation-delay: 0.373333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(29) {
    transform: rotate(348deg);
  }
  .Profile-border-segmentContainer:nth-child(29) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.386666666666667s;
    animation-delay: 0.386666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(30) {
    transform: rotate(360deg);
  }
  .Profile-border-segmentContainer:nth-child(30) .Profile-border-segment {
    transform-origin: 73px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
}
@media only screen and (max-width: 640px) {
  .Profile-border-segmentContainer:nth-child(1) {
    transform: rotate(12deg);
  }
  .Profile-border-segmentContainer:nth-child(1) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.013333333333333s;
    animation-delay: 0.013333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(2) {
    transform: rotate(24deg);
  }
  .Profile-border-segmentContainer:nth-child(2) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.026666666666667s;
    animation-delay: 0.026666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(3) {
    transform: rotate(36deg);
  }
  .Profile-border-segmentContainer:nth-child(3) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.04s;
    animation-delay: 0.04s;
  }
  .Profile-border-segmentContainer:nth-child(4) {
    transform: rotate(48deg);
  }
  .Profile-border-segmentContainer:nth-child(4) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.053333333333333s;
    animation-delay: 0.053333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(5) {
    transform: rotate(60deg);
  }
  .Profile-border-segmentContainer:nth-child(5) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.066666666666667s;
    animation-delay: 0.066666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(6) {
    transform: rotate(72deg);
  }
  .Profile-border-segmentContainer:nth-child(6) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.08s;
    animation-delay: 0.08s;
  }
  .Profile-border-segmentContainer:nth-child(7) {
    transform: rotate(84deg);
  }
  .Profile-border-segmentContainer:nth-child(7) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.093333333333333s;
    animation-delay: 0.093333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(8) {
    transform: rotate(96deg);
  }
  .Profile-border-segmentContainer:nth-child(8) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.106666666666667s;
    animation-delay: 0.106666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(9) {
    transform: rotate(108deg);
  }
  .Profile-border-segmentContainer:nth-child(9) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.12s;
    animation-delay: 0.12s;
  }
  .Profile-border-segmentContainer:nth-child(10) {
    transform: rotate(120deg);
  }
  .Profile-border-segmentContainer:nth-child(10) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.133333333333333s;
    animation-delay: 0.133333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(11) {
    transform: rotate(132deg);
  }
  .Profile-border-segmentContainer:nth-child(11) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.146666666666667s;
    animation-delay: 0.146666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(12) {
    transform: rotate(144deg);
  }
  .Profile-border-segmentContainer:nth-child(12) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.16s;
    animation-delay: 0.16s;
  }
  .Profile-border-segmentContainer:nth-child(13) {
    transform: rotate(156deg);
  }
  .Profile-border-segmentContainer:nth-child(13) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.173333333333333s;
    animation-delay: 0.173333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(14) {
    transform: rotate(168deg);
  }
  .Profile-border-segmentContainer:nth-child(14) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.186666666666667s;
    animation-delay: 0.186666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(15) {
    transform: rotate(180deg);
  }
  .Profile-border-segmentContainer:nth-child(15) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .Profile-border-segmentContainer:nth-child(16) {
    transform: rotate(192deg);
  }
  .Profile-border-segmentContainer:nth-child(16) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.213333333333333s;
    animation-delay: 0.213333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(17) {
    transform: rotate(204deg);
  }
  .Profile-border-segmentContainer:nth-child(17) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.226666666666667s;
    animation-delay: 0.226666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(18) {
    transform: rotate(216deg);
  }
  .Profile-border-segmentContainer:nth-child(18) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.24s;
    animation-delay: 0.24s;
  }
  .Profile-border-segmentContainer:nth-child(19) {
    transform: rotate(228deg);
  }
  .Profile-border-segmentContainer:nth-child(19) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.253333333333333s;
    animation-delay: 0.253333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(20) {
    transform: rotate(240deg);
  }
  .Profile-border-segmentContainer:nth-child(20) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.266666666666667s;
    animation-delay: 0.266666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(21) {
    transform: rotate(252deg);
  }
  .Profile-border-segmentContainer:nth-child(21) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.28s;
    animation-delay: 0.28s;
  }
  .Profile-border-segmentContainer:nth-child(22) {
    transform: rotate(264deg);
  }
  .Profile-border-segmentContainer:nth-child(22) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.293333333333333s;
    animation-delay: 0.293333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(23) {
    transform: rotate(276deg);
  }
  .Profile-border-segmentContainer:nth-child(23) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.306666666666667s;
    animation-delay: 0.306666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(24) {
    transform: rotate(288deg);
  }
  .Profile-border-segmentContainer:nth-child(24) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.32s;
    animation-delay: 0.32s;
  }
  .Profile-border-segmentContainer:nth-child(25) {
    transform: rotate(300deg);
  }
  .Profile-border-segmentContainer:nth-child(25) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.333333333333333s;
    animation-delay: 0.333333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(26) {
    transform: rotate(312deg);
  }
  .Profile-border-segmentContainer:nth-child(26) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.346666666666667s;
    animation-delay: 0.346666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(27) {
    transform: rotate(324deg);
  }
  .Profile-border-segmentContainer:nth-child(27) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.36s;
    animation-delay: 0.36s;
  }
  .Profile-border-segmentContainer:nth-child(28) {
    transform: rotate(336deg);
  }
  .Profile-border-segmentContainer:nth-child(28) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.373333333333333s;
    animation-delay: 0.373333333333333s;
  }
  .Profile-border-segmentContainer:nth-child(29) {
    transform: rotate(348deg);
  }
  .Profile-border-segmentContainer:nth-child(29) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.386666666666667s;
    animation-delay: 0.386666666666667s;
  }
  .Profile-border-segmentContainer:nth-child(30) {
    transform: rotate(360deg);
  }
  .Profile-border-segmentContainer:nth-child(30) .Profile-border-segment {
    transform-origin: 50px 50%;
    -webkit-animation: scale 2.5s infinite linear;
    animation: scale 2.5s infinite linear;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .mySwiper-blog .swiper-button-next,
  .mySwiper-blog .swiper-button-prev {
    display: none;
  }
}
